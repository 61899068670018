<template>
  <div>
    <div class="service-body container-fluid">
        <div class="service-body-title">特徴と構成イメージ</div>
        <span>01</span>
        <div class="service-body-desc row">
            <div class="col-md-6 col-sm-12">
                <img draggable="false" class="service-body-image" src="@/assets/images/desc/img_0.png" alt="">
            </div>
            <div class="col-md-6 col-sm-12">
                <ul class="service-body-ul">
                    <li>
                        <div>
                            <img draggable="false" src="@/assets/images/desc/num_1.png" alt="">
                        </div>
                        <span>デバイス、GW、クラウドサービスを提供しているので、どの部分でもカスタマイズ可能</span>
                    </li>
                    <li>
                        <div>
                            <img draggable="false" src="@/assets/images/desc/num_2.png" alt="">
                        </div>
                        <span>データのインプットからサービスのアウトプットまでを一括提供</span>
                    </li>
                    <li>
                        <div>
                            <img draggable="false" src="@/assets/images/desc/num_3.png" alt="">
                        </div>
                        <span>顧客の要求を柔軟に対応することが可能</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div style="height:580px;background-color:#FFFFFF;" class="service-body container-fluid">
        <div class="service-body-title">特徴と構成イメージ</div>
        <span>02</span>
        <div class="service-body-sqare">
            <div :style="{backgroundImage:'url('+item.imageUrl+')'}" v-for="(item, index) in serviceSqareData" :key="index">
                <div @click="smDesc=item.desc" @mouseenter="maskHover(index)" @mouseleave="recoverHover(index)" class="service-body-sqare-mask">
                    <div :id="'mask'+index" class="service-mask-container">
                        <div>
                            <img draggable="false" class="service-mask-img" :src="item.logoUrl" alt="">
                        </div>
                        <div style="width:62px;margin:0 auto;padding:20px;border-bottom:3px solid #FFF;" class="divider"></div>
                        <div class="service-body-sqare-text">
                            {{item.text}}
                        </div>
                        <div class="service-body-sqare-desc" :id="'desc'+index">
                            {{item.desc}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="sm-desc">{{smDesc}}</div>
    </div>
    <div style="height:100%;" class="service-body container-fluid">
        <div class="service-body-title">プラットフォームの特徴</div>
        <span>03</span>
        <div class="service-options-container">
            <div v-for="(item, index) in optionsData" :key="index">
                <div>
                    <img draggable="false" :src="require('@/assets/images/desc/num_'+(index+1)+'.png')" alt="">
                </div>
                <div class="service-options-desc">
                    {{item.desc}}
                </div>
            </div>
        </div>
    </div>
    <div style="background-color:#FFFFFF;padding-bottom:50px;height:100%;" class="service-body container-fluid">
        <div class="service-body-title">データ収集システムの構成</div>
        <span>04</span>
        <div class="service-constractor-container">
            <div>
                <img draggable="false" style="width:80vw;" :src="require('@/assets/images/desc/img_5.png')" alt="">
            </div>
        </div>
    </div>
    <div style="height:100%;background-color:#1B2144" class="service-body container-fluid">
        <div class="service-body-title" style="color:#FFFFFF">データ分析体系</div>
        <span style="color:#FFFFFF">05</span>
        <div class="service-management-container">
            <div style="width:100%;margin-right:14px;">
                <img draggable="false" style="width:40vw;" :src="require('@/assets/images/desc/img_6.png')" alt="">
            </div>
            <div>
                <img draggable="false" style="width:38vw;" :src="require('@/assets/images/desc/img_7.png')" alt="">
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    components:{

    },
    data(){
        return{
            smDesc:'',
            subNavSelected:0,
            optionsData:[
                {
                    index:0,
                    desc:'プロビジョニングおよびデバイスを制御する'
                },{
                    index:1,
                    desc:'デバイスからデータを収集して可視化する'
                },{
                    index:2,
                    desc:'デバイスデータを分析およびアラームをトリガする'
                },{
                    index:3,
                    desc:'デバイスデータを他のシステムに配信する'
                },{
                    index:4,
                    desc:'利用シーンによって、カスタマイズ可能なウィジェット、ルールとプラグインを使用する'
                }
            ],
            serviceSqareData:[
                {
                    index:0,
                    imageUrl:require('@/assets/images/desc/img_1.png'),
                    logoUrl:require('@/assets/images/desc/icon_tenzheng1.png'),
                    text:'低消費電力',
                    desc:'ローパワーデバイス設計による通信；導入の容易さ、バッテリー駆動、2年稼働(10分/1回通信)',
                },{
                    index:1,
                    imageUrl:require('@/assets/images/desc/img_2.png'),
                    logoUrl:require('@/assets/images/desc/icon_tenzheng2.png'),
                    text:'ワイヤレスでデータの収集性向上',
                    desc:'配線不要、どこでも設置可能；冷凍、冷蔵環境でも設置可能'
                },{
                    index:2,
                    imageUrl:require('@/assets/images/desc/img_3.png'),
                    logoUrl:require('@/assets/images/desc/icon_tenzheng3.png'),
                    text:'導入が容易、低コスト',
                    desc:'従来のデータ収集デバイスより、設置が容易、低コスト;既存の環境に導入することができるため、システム依存性なし'
                },{
                    index:3,
                    imageUrl:require('@/assets/images/desc/img_4.png'),
                    logoUrl:require('@/assets/images/desc/icon_tenzheng4.png'),
                    text:'各種類のデバイスが調達可能',
                    desc:'中国のメーカーを利用し、低価格、高性能の各種類デバイスを調達'
                }
            ],
            subNavBarData:[
                {
                    index:0,
                    imageUrl:require('@/assets/images/desc/icon_iot.png'),
                    text:'IoT研究開発',
                    name:'iot',
                    url:'iot',
                    title:'IoT研究開発',
                    desc:'モノのインターネット製品のための設備接続、メッセージの送受信、データ管理クラウドサービスです。',
                    bgImg:require('@/assets/images/desc/banner2@2x.png'),
                    mgImg:require('@/assets/images/desc/banner_mobile.png')
                },
                {
                    index:1,
                    imageUrl:require('@/assets/images/desc/icon_qukuailian.png'),
                    text:'ブロックチェイン',
                    name:'btc',
                    url:'btc'
                },
                {
                    index:2,
                    imageUrl:require('@/assets/images/desc/icon_xinyongka.png'),
                    text:'クレジットカード',
                    name:'creditcard',
                    url:'creditcard'
                },
                {
                    index:3,
                    imageUrl:require('@/assets/images/desc/icon_xitongkaifa.png'),
                    text:'システム開発',
                    name:'dev',
                    url:'dev'
                },
                {
                    index:4,
                    imageUrl:require('@/assets/images/desc/icon_car.png'),
                    text:'SMARTCAR',
                    name:'smtcar',
                    url:'smtcar'
                },
            ]
        }
    },
    methods: {
        /* eslint-disable */
        maskHover(index){
            if(document.body.clientWidth >768){
                document.getElementById('mask'+index).style.webkitTransform = "translate(0px,-80px)"
                document.getElementById('mask'+index).style.transition = "all .5s"
                document.getElementById('desc'+index).style.opacity = "1"
                document.getElementById('desc'+index).style.transition = "all .5s"
            } 
        },
        recoverHover(index){
            if(document.body.clientWidth >768){
                document.getElementById('mask'+index).style.webkitTransform = ""
                document.getElementById('desc'+index).style.opacity = "0"
            }
        }
    },
}
</script>

<style>

</style>